<script setup lang="ts">
import dayjs from "dayjs";

const currentYear = dayjs().year();
</script>

<template>
    <div class="footer-area footer-section--7 position-relative bg-default-3">
        <div class="container">
            <footer class="footer-top">
                <div class="row">
                    <div class="col-lg-3 col-md-7 col-xs-9">
                        <div class="footer-widgets footer-widgets--l7">
                            <!-- Brand Logo-->
                            <div class="brand-logo mt-1">
                                <a href="#">
                                    <!-- light version logo (logo must be black)-->
                                    <img src="/images/logo.png" alt="" class="lightversion-logo">
                                    <!-- Dark version logo (logo must be White)-->
                                    <img src="/images/logo.png" alt="" class="dark-version-logo">
                                </a>
                            </div>
                            <p class="footer-widgets__text mt-5">
                                We’re a software development agency and create new tools regularly.</p>
                            <ul class="footer-social-share footer-social-share--rounded mt-4">
                                <li>
                                    <a href="#">
                                        <i class="fab fa-facebook-square"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fab fa-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fab fa-linkedin"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div class="row">
                            <div class="col-md-4 col-xs-6">
                                <div class="footer-widgets footer-widgets--l7">
                                    <h4 class="footer-widgets__title">Contact Details</h4>
                                    <ul class="footer-widgets__list footer-widgets--address">
                                        <li>
                                            <i class="fa fa-envelope text-egg-blue"></i>
                                            <a class="heading-default-color gr-text-hover-underline text-break"
                                               href="mailto:info@ultimateemailverifier.com">
                          <span class="d-block gr-text-hover-decoration-none"> Email:
                                            </span>info@ultimateemailverifier.com</a>
                                        </li>
                                        <li>
                                            <i class="fa fa-ticket-alt text-egg-blue"></i>
                                            <a class="heading-default-color gr-text-hover-underline text-break"
                                               href="/support">
                                                <span class="d-block gr-text-hover-decoration-none">
                                                    Contact Us
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-4 col-xs-6">
                                <div class="footer-widgets footer-widgets--l7 mb-10 mb-md-0">
                                    <h4 class="footer-widgets__title">Company</h4>
                                    <ul class="footer-widgets__list">
                                        <!--                                        <li>
                                                                                    <a href="#">About us</a>
                                                                                </li>-->
                                        <li>
                                            <a href="/terms-of-use">Terms of Use</a>
                                        </li>
                                        <li>
                                            <a href="/privacy-policy">Privacy Policy</a>
                                        </li>
                                        <li>
                                            <a href="/eu-privacy-policy">EU Privacy Policy</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-4 col-xs-9">
                                <div class="footer-widgets footer-widgets--l7">
                                    <h4 class="footer-widgets__title">Accurate Results. Better Inboxing.</h4>
<!--                                    <p class="footer-widgets__text mt-0 mb-2">
                                        <strong></strong><br>
                                    </p>-->
                                    <p class="footer-widgets__text mt-0 mb-2">
                                        Enjoy up to 500 free credits each month while you experience our high-quality
                                        service.
                                    </p>

                                    <p class="footer-widgets__text mt-0 mb-2">
                                        Ready to see the difference?
                                    </p>

                                    <!-- Newsletter -->
                                    <div class="newsletter newsletter--l7">
                                        <form action="/.">
                                            <a href="/register" class="btn btn-readical-red shadow--readical-red-3">
                                                Register Now
                                            </a>
                                        </form>
                                    </div>
                                    <!--/ .Newsletter -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div class="copyright text-center border-top border-default-color-3">
                <p class="mb-0">© {{ currentYear }} UltimateEmailVerifier.com. All Rights Reserved</p>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
